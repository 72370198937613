
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import BaseTeamMemberCard
  from '@improve/common-components/src/components/widgets/BaseTeamMemberCard.vue';
import GaleraRoles from '@improve/common-utils/src/types/GaleraRoles';

@Component({
  name: 'TeamMembersTab',
  components: {
    BaseTeamMemberCard
  }
})
export default class TeamMembersTab extends Vue {
  @Prop({ default: null }) readonly team!: Team;

  @Prop({ default: () => [] }) readonly teamMembers!: Array<User>;

  @Getter allTeamsById!: (id: string) => Team;

  @Getter teamMembersByTeamId!: Map<string, Map<string, number>>;

  userUnitName(member: User): string {
    const unit = member.unit && this.allTeamsById(member.unit);
    return (unit && unit.name) || '';
  }

  userIsTeamOwner(id: string): boolean {
    return this.teamMembersByTeamId.get(this.team.id!)?.get(id) === GaleraRoles.OwnerUserLevel;
  }
}
